'use client';
import type { CountryCode } from '@carbonfact/shared/src/geographical-areas';
import { CircleFlag } from '@carbonfact/ui-components/src/CircleFlag';
import { Combobox, ComboboxButton, ComboboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { type ReactNode, useEffect, useMemo, useState } from 'react';

import { compact, uniq } from 'lodash';
import {
  DropdownOptionPrimitive,
  type DropdownOptionPrimitiveProps,
} from '../../primitives';
import { AutocompleteInputPrimitive } from '../../primitives/AutoCompleteInputPrimitive';
import { DropdownButtonPrimitive } from '../../primitives/DropdownButtonPrimitive';
import { DropdownOptionGroupPrimitive } from '../../primitives/DropdownOptionGroupPrimitive';
import { DropdownOptionsScrollerPrimitive } from '../../primitives/DropdownOptionsScrollerPrimitive';

type OptionType = DropdownOptionPrimitiveProps<CountryCode>['option'];
export type DropdownCountryProps = {
  label?: string;
  options: OptionType[];
  value?: CountryCode;
  disabled?: boolean;
  backgroundColor?: string;
  onChange: (val: CountryCode) => void;
  className?: string;
  height?: string;
  groups?: {
    label: string;
    optionKeys: OptionType['value'][];
  }[];
  showArrow?: boolean;
  actions?: ReactNode;
  autocomplete?: boolean;
};
export const Country = ({
  label,
  options,
  disabled,
  showArrow = true,
  backgroundColor,
  className = '',
  value,
  height = 'h-8',
  onChange,
  actions,
  groups,
  autocomplete,
}: DropdownCountryProps) => {
  const t = useTranslations();
  const [selected, setSelected] = useState(
    (value !== undefined && options.find((o) => o.value === value)) ||
      options[0],
  );
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState('');

  if (selected === undefined) {
    return null;
  }

  useEffect(() => {
    setSelected(
      (value !== undefined && options.find((o) => o.value === value)) ||
        options[0],
    );
  }, [value, options[0]]);

  if (options.length === 0) {
    return null;
  }
  let bgColor = 'bg-white';

  if (backgroundColor) {
    bgColor = backgroundColor;
  }
  const handleOnChange = (option: OptionType) => {
    setSelected(option);
    onChange(option?.value);
  };

  const filteredOptions = autocomplete
    ? options.filter((option) =>
        option.label
          .toLowerCase()
          .includes(autocompleteSearchTerm.toLowerCase()),
      )
    : options;

  const optionsMap = useMemo(() => {
    const map = new Map<CountryCode, OptionType>();
    for (const option of options) {
      map.set(option.value, option);
    }
    return map;
  }, [options]);

  const groupedOptionsMap = useMemo(() => {
    // Build a map to know which option is grouped
    const map = new Map<CountryCode, true>();
    for (const group of groups || []) {
      for (const option of group.optionKeys) {
        map.set(option, true);
      }
    }
    return map;
  }, [groups]);

  const isDisabled = disabled || options.length <= 1;
  return (
    <Combobox
      disabled={isDisabled}
      value={selected}
      onChange={handleOnChange}
      onClose={() => setAutocompleteSearchTerm('')}
    >
      {({ open }) => (
        <div
          className={classNames(
            'relative border-carbon-100 bg-carbon-50',
            height,
            className,
          )}
        >
          {(!autocomplete || (autocomplete && !open)) && (
            <ComboboxButton
              className={classNames(
                'rounded-md shadow-carbon border-[1px] box-border border-carbon-100 items-center px-1 focus:outline-hidden h-full w-full justify-between flex flex-row',
                bgColor,
              )}
            >
              <DropdownButtonPrimitive
                disabled={isDisabled}
                showArrow={showArrow}
                prefix={label}
              >
                {selected && (
                  <div className="w-5">
                    <CircleFlag countryCode={selected.value} />
                  </div>
                )}
                {selected && (
                  <p className="text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                    {selected.label}
                  </p>
                )}
              </DropdownButtonPrimitive>
            </ComboboxButton>
          )}
          {open && (
            <ComboboxOptions
              static
              className="absolute p-2 left-0 z-50 w-72 origin-top-right max-h-[50vh] overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-hidden"
            >
              {autocomplete && (
                <AutocompleteInputPrimitive
                  placeholder={value}
                  label={label}
                  onChange={setAutocompleteSearchTerm}
                  value={autocompleteSearchTerm}
                />
              )}
              {filteredOptions.length === 0 && (
                <div className="p-1 text-sm text-carbon-500">
                  {t('Dropdown.noResultFound')}
                </div>
              )}
              <DropdownOptionsScrollerPrimitive>
                {actions}
                {groups?.map((group, index) => {
                  const groupOptions = compact(
                    // Handling duplicate keys & missing keys
                    uniq(group.optionKeys).map((key) => optionsMap.get(key)),
                  ).filter((option) =>
                    filteredOptions.some((fo) => fo.value === option?.value),
                  );

                  if (groupOptions.length === 0) {
                    return null;
                  }
                  return (
                    <DropdownOptionGroupPrimitive
                      index={index}
                      label={group.label}
                      key={group.label}
                    >
                      {groupOptions.map((option) => (
                        <DropdownOptionPrimitive
                          key={`${group.label}-${option.value}`}
                          option={option}
                          selected={option.value === value}
                        >
                          <CircleFlag countryCode={option.value} />
                          {option.label}
                        </DropdownOptionPrimitive>
                      ))}
                    </DropdownOptionGroupPrimitive>
                  );
                })}
                {
                  // Show group-less options
                  filteredOptions
                    .filter((option) => !groupedOptionsMap.has(option.value))
                    .map((option) => (
                      <DropdownOptionPrimitive
                        key={option.value}
                        option={option}
                        selected={option.value === value}
                      >
                        <div className="flex flex-row items-center gap-2">
                          <CircleFlag countryCode={option.value} />
                          {option.label}
                        </div>
                      </DropdownOptionPrimitive>
                    ))
                }
              </DropdownOptionsScrollerPrimitive>
            </ComboboxOptions>
          )}
        </div>
      )}
    </Combobox>
  );
};
