import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { forwardRef, useCallback, useMemo } from 'react';

// When the component is handled by a headlessui component,
// the onChange event needs to send an Event object
// If it's normally used, it should send the value directly
// Hence why we have the "Forwarded" variants
interface BaseInputProps {
  className?: string;
  placeholder?: string;
  label?: string;
  suffix?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  layout?: 'horizontal' | 'vertical';
  onFocus?: () => void;

  // Forwarded variants types
  // Default is unforwarded, onChange is called with value directly
  isForwarded?: boolean;
  onChange?:
    | ((value: string) => void)
    | ((value: number) => void)
    | ((event: React.ChangeEvent<HTMLInputElement>) => void);

  // Number/text variant types
  // Default is text
  type?: 'search' | 'text' | 'number' | 'range';
  value?: string | number;

  rawHtmlProps?: React.HTMLProps<HTMLInputElement>;
}

interface TextInputProps extends BaseInputProps {
  isForwarded?: false;
  onChange?: (value: string) => void;
  type?: 'search' | 'text';
  value?: string;
}

interface ForwardedTextInputProps extends BaseInputProps {
  isForwarded: true;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: 'search' | 'text';
  value?: string;
}

interface NumberInputProps extends BaseInputProps {
  isForwarded?: false;
  onChange?: (value: number | null) => void;
  type: 'number';
  value?: number;
  step?: number;
  min?: number;
  max?: number;
}

interface NumberRangeInputProps extends BaseInputProps {
  isForwarded?: false;
  onChange?: (value: number | null) => void;
  type: 'range';
  value?: number;
  step?: number;
  min?: number;
  max?: number;
}

interface ForwardedNumberInputProps extends BaseInputProps {
  isForwarded: true;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: 'number';
  value?: number;
  step?: number;
  min?: number;
  max?: number;
}

type InputProps =
  | TextInputProps
  | ForwardedTextInputProps
  | NumberInputProps
  | NumberRangeInputProps
  | ForwardedNumberInputProps;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      placeholder,
      label,
      suffix,
      disabled,
      autoFocus,
      onFocus,
      layout,
      // Do not destructure the other props to keep the type link between the props and the different variants
      ...props
    },
    ref,
  ) => {
    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.isForwarded) {
          props.onChange?.(event);
        } else if (props.onChange !== undefined) {
          if (props.type === 'number' || props.type === 'range') {
            const valueAsNumber = event.target.valueAsNumber;
            // Check if valueAsNumber is a valid number
            if (!Number.isNaN(valueAsNumber)) {
              props.onChange(valueAsNumber);
            } else {
              // Handle invalid number scenario, e.g., passing undefined
              props.onChange(null);
            }
          } else {
            props.onChange(event.target.value);
          }
        }
      },
      [props.onChange, props.type, props.isForwarded],
    );

    const htmlId = useMemo(() => uniqueId('input_'), []);

    const inputClassName = className
      ?.split(' ')
      .filter((c) => c.includes('bg-') || c.includes('text-'))
      .join(' ');
    const classNameWithoutBgOrText = className
      ?.split(' ')
      .filter((c) => !c.includes('bg-') && !c.includes('text-'))
      .join(' ');

    return (
      <div
        ref={ref}
        className={classNames(
          classNameWithoutBgOrText,
          'flex  gap-1',
          layout === 'horizontal'
            ? 'flex-row items-center'
            : 'flex-col items-stretch',
        )}
      >
        {label && (
          <label
            htmlFor={htmlId}
            className="mr-2 select-none font-medium text-sm text-carbon-800"
          >
            {label}
          </label>
        )}
        <div
          className={classNames(
            inputClassName,
            'flex flex-row items-center h-9 px-2 border-[1px] rounded-md overflow-hidden w-full',
            props.type === 'range' ? 'border-white' : 'border-carbon-200',
            disabled
              ? 'bg-gray-50 cursor-not-allowed'
              : 'hover:border-gray-300 hover:bg-gray-50 focus-within:bg-gray-50 focus:bg-gray-50 focus:border-gray-300 focus-within:border-gray-300',
          )}
        >
          <input
            id={htmlId}
            onChange={handleOnChange}
            type={props.type}
            // Make sure HTML element input is always controlled and never
            // undefined
            value={props.value ?? ''}
            role="textbox"
            autoFocus={autoFocus}
            onFocus={onFocus}
            placeholder={placeholder || ''}
            className={classNames(
              inputClassName,
              'bg-transparent inline-block border-none w-full p-0 text-sm placeholder:text-carbon-400 focus:ring-0 accent-gray-500',
              props.type === 'range' && 'cursor-grab active:cursor-grabbing',
              disabled && 'bg-gray-100 cursor-not-allowed',
            )}
            disabled={disabled}
            step={
              props.type === 'number' || props.type === 'range'
                ? props.step
                : undefined
            }
            min={
              props.type === 'number' || props.type === 'range'
                ? props.min
                : undefined
            }
            max={
              props.type === 'number' || props.type === 'range'
                ? props.max
                : undefined
            }
            {...props.rawHtmlProps}
          />
          {suffix && (
            <span className="text-sm ml-2 text-carbon-600">{suffix}</span>
          )}
        </div>
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
