import classNames from 'classnames';

type SkeletonProps = {
  height?: string;
  width?: string;
  className?: string;
};
const Skeleton = ({
  height = 'h-4',
  width = 'w-full',
  className,
}: SkeletonProps) => {
  return (
    <div
      data-test="skeleton"
      className={classNames(
        'p-0 bg-gray-200 rounded-lg animate-pulse inline-block',
        height,
        width,
        className,
      )}
    />
  );
};
export default Skeleton;
