import { Input } from '../../Form';

type AutocompleteInputPrimitiveProps = {
  value: string;
  onChange: (val: string) => void;
  label?: string;
  placeholder?: string;
  selectedText?: string;
};
export const AutocompleteInputPrimitive = ({
  value,
  onChange,
  label,
  placeholder,
}: AutocompleteInputPrimitiveProps) => {
  return (
    <div className="flex flex-row items-center bg-white">
      <Input
        className="w-full"
        type="text"
        placeholder={placeholder}
        label={label}
        autoFocus
        onChange={onChange}
        value={value}
      />
    </div>
  );
};
