import Skeleton from '@carbonfact/ui-components/src/Skeleton';
import { Combobox, ComboboxButton, ComboboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { type ReactNode, useCallback, useMemo, useState } from 'react';

import { compact, uniq } from 'lodash';
import {
  DropdownOptionPrimitive,
  type DropdownOptionPrimitiveProps,
} from '../../primitives';
import { AutocompleteInputPrimitive } from '../../primitives/AutoCompleteInputPrimitive';
import { DropdownButtonPrimitive } from '../../primitives/DropdownButtonPrimitive';
import { DropdownOptionGroupPrimitive } from '../../primitives/DropdownOptionGroupPrimitive';
import { DropdownOptionsScrollerPrimitive } from '../../primitives/DropdownOptionsScrollerPrimitive';
import { PlaceholderPrimitive } from '../../primitives/PlaceholderPrimitive';

type OptionType<T> = DropdownOptionPrimitiveProps<T>['option'];

export type DropdownDefaultProps<T extends React.Key | null = string> = {
  label?: string;
  options: OptionType<T>[];
  loading?: boolean;
  value: T | null;
  disabled?: boolean;
  onChange: (val: T) => void;
  className?: string;
  height?: string;
  placeholder?: string;
  maxDisplaySize?: number;
  autocomplete?: boolean;
  optionsDisplayType?: 'relative' | 'absolute';
  showArrow?: boolean;
  actions?: ReactNode;
  groups?: {
    label: string;
    optionKeys: OptionType<T>['value'][];
  }[];
  align?: 'left' | 'right';
};
export const Dropdown = <T extends React.Key | null = string>({
  label,
  options,
  disabled,
  className = '',
  loading,
  placeholder,
  value,
  height = 'h-9',
  maxDisplaySize = 100,
  showArrow = true,
  // Should the options be displayed in an absolute or relative container
  // TODO: who cares?? can we make the pop up smart enough to know where to go?
  // we shouldn't have to pass such low-level options
  optionsDisplayType = 'absolute',
  onChange,
  autocomplete,
  actions,
  groups,
  align = 'left',
}: DropdownDefaultProps<T>) => {
  const t = useTranslations();
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState('');
  const [hoveredOption, setHoveredOption] = useState<OptionType<T> | null>(
    null,
  );

  if (!placeholder) {
    placeholder = t('Dropdown.selectOption');
  }
  const handleChange = useCallback(
    (newPickedOption: OptionType<T>) => {
      if (!newPickedOption) {
        return;
      }
      onChange(newPickedOption?.value);
    },
    [onChange],
  );

  const optionsMap = useMemo(() => {
    const map = new Map<T, OptionType<T>>();
    for (const option of options) {
      map.set(option.value, option);
    }
    return map;
  }, [options]);

  const groupedOptionsMap = useMemo(() => {
    // Build a map to know which option is grouped
    const map = new Map<T, true>();
    for (const group of groups || []) {
      for (const option of group.optionKeys) {
        map.set(option, true);
      }
    }
    return map;
  }, [groups]);

  const { filteredOptions, filteredResultsLength } = useMemo(() => {
    if (!autocomplete) {
      return {
        filteredOptions: options,
        filteredResultsLength: options.length,
      };
    }

    let filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(autocompleteSearchTerm.toLowerCase()),
    );

    const filteredResultsLength = filteredOptions.length;

    if (filteredOptions.length > maxDisplaySize) {
      filteredOptions = filteredOptions.slice(0, maxDisplaySize);
    }

    return { filteredOptions, filteredResultsLength };
  }, [options, autocomplete, autocompleteSearchTerm, maxDisplaySize]);

  const valueOption = options.find((anOption) => anOption.value === value);
  const isDisabled = disabled || options.length < 1;

  if (loading) {
    return <Skeleton height={height} width="min-w-[90px] w-full" />;
  }

  let forceDisabled = false;
  if (options.length === 0) {
    forceDisabled = true;
  }

  const containerBoxClass = 'bg-white p-2 focus:outline-hidden w-full';

  return (
    // TODO: maybe we should be using Menu instead...?
    // https://headlessui.com/react/menu
    <Combobox
      disabled={isDisabled || forceDisabled}
      value={valueOption || null}
      onChange={handleChange}
      onClose={() => setAutocompleteSearchTerm('')}
    >
      {({ open }) => (
        <div
          className={classNames(
            'relative border-carbon-100 w-fit transition-all',
            height,
            className,
          )}
        >
          {(!autocomplete || (autocomplete && !open)) && (
            <ComboboxButton className="h-full w-full">
              <DropdownButtonPrimitive
                showArrow={showArrow}
                disabled={isDisabled}
                prefix={label}
              >
                {!value && <PlaceholderPrimitive text={placeholder} />}
                {value && (
                  <p className="text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                    {valueOption?.label}
                  </p>
                )}
              </DropdownButtonPrimitive>
            </ComboboxButton>
          )}

          {open && (
            <>
              <ComboboxOptions
                static
                className={classNames(
                  'rounded-md shadow-lg ring-1 ring-black/5 z-50 flex flex-row mb-20 min-w-full origin-top-right overflow-hidden gap-2 ',
                  align === 'left' ? 'left-0' : 'right-0',
                  optionsDisplayType === 'absolute'
                    ? 'absolute'
                    : 'relative top-[-0.5rem] left-[-0.5rem]',
                )}
              >
                <div className={containerBoxClass}>
                  {autocomplete && (
                    <AutocompleteInputPrimitive
                      placeholder={placeholder}
                      selectedText={valueOption?.label}
                      label={label}
                      onChange={setAutocompleteSearchTerm}
                      value={autocompleteSearchTerm}
                    />
                  )}
                  {filteredOptions.length === 0 && (
                    <div className="p-1 text-sm text-carbon-500">
                      {t('Dropdown.noResultFound')}
                    </div>
                  )}
                  {autocomplete &&
                    filteredOptions.length > 0 &&
                    filteredOptions.length !== filteredResultsLength && (
                      <div className="p-1 text-xs text-carbon-500 font-medium border-b border-carbon-100 flex pb-2">
                        {t('Dropdown.displayingXOptions', {
                          count: filteredOptions.length,
                          total: filteredResultsLength,
                        })}
                      </div>
                    )}

                  {actions}

                  <DropdownOptionsScrollerPrimitive>
                    {
                      // Show group-less NULL / reset options first
                      filteredOptions
                        .filter(
                          (option) =>
                            !groupedOptionsMap.has(option.value) &&
                            option.value === null,
                        )
                        .map((option) => (
                          <div key={option.value}>
                            <DropdownOptionPrimitive
                              option={option}
                              onHover={(val) => setHoveredOption(val)}
                              selected={option.value === value}
                            >
                              {option.label}
                            </DropdownOptionPrimitive>
                          </div>
                        ))
                    }
                    {
                      // Show options within groups
                      groups?.map((group, index) => {
                        const groupOptions = compact(
                          // Handling duplicate keys & missing keys
                          uniq(group.optionKeys).map((key) =>
                            optionsMap.get(key),
                          ),
                        ).filter((option) =>
                          filteredOptions.some(
                            (fo) => fo.value === option?.value,
                          ),
                        );

                        if (groupOptions.length === 0) {
                          return null;
                        }

                        return (
                          <DropdownOptionGroupPrimitive
                            key={group.label}
                            index={
                              index +
                              (filteredOptions.find(
                                (option) =>
                                  !groupedOptionsMap.has(option.value) &&
                                  option.value === null,
                              )
                                ? 1
                                : 0)
                            }
                            label={group.label}
                          >
                            {groupOptions.map((option) => (
                              <DropdownOptionPrimitive
                                key={`${group.label}-${option.value}`}
                                option={option}
                                onHover={(val) => setHoveredOption(val)}
                                selected={option.value === value}
                              >
                                {option.label}
                              </DropdownOptionPrimitive>
                            ))}
                          </DropdownOptionGroupPrimitive>
                        );
                      })
                    }
                    {
                      // Show group-less options
                      filteredOptions
                        .filter(
                          (option) =>
                            !groupedOptionsMap.has(option.value) &&
                            option.value !== null,
                        )
                        .map((option) => (
                          <DropdownOptionPrimitive
                            key={option.value}
                            option={option}
                            onHover={(val) => setHoveredOption(val)}
                            selected={option.value === value}
                          >
                            {option.label}
                          </DropdownOptionPrimitive>
                        ))
                    }
                  </DropdownOptionsScrollerPrimitive>
                </div>
              </ComboboxOptions>
              {hoveredOption && (
                <div
                  className={classNames(
                    containerBoxClass,
                    'absolute left-full ml-[-4px] top-0 text-gray-900 text-sm w-64 z-50 whitespace-normal p-4 rounded-md  shadow-lg ring-1 ring-black/5 ',
                  )}
                >
                  {hoveredOption.hoverContent}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Combobox>
  );
};
