import { ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

export const DropdownButtonPrimitive = ({
  prefix,
  disabled,
  showArrow,
  children,
}: {
  prefix?: string;
  showArrow: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(
        'rounded-md box-border bg-white border-[1px] border-carbon-200 h-full w-full px-2 justify-between flex flex-row items-center',
        disabled
          ? 'cursor-not-allowed opacity-50'
          : 'cursor-pointer hover:border-gray-300 focus:border-gray-300 hover:bg-gray-50 active:bg-gray-100 items-center focus:outline-hidden',
      )}
    >
      <div className="inline-flex z-30 items-center gap-1 rounded-l-md text-carbon-700 overflow-hidden">
        {prefix && (
          <p className="text-sm font-medium whitespace-nowrap">{prefix}</p>
        )}
        {children}
      </div>
      {showArrow && (
        <ChevronDownIcon
          className="h-5 w-5 text-[#192026]"
          aria-hidden="true"
        />
      )}
    </div>
  );
};
